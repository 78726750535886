import { Button } from "@/UI/Button";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { classNames } from "@/utils/helpers/classNames";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useEditForm } from "@/lib/react-query/mutationHooks/settings/useEditForm";
import { Alert } from "@/UI/Alert";
import { useAlert } from "@/utils/hooks/useAlert";
import { toast } from "react-toastify";
import { LoadingSpinner } from "@/UI/Loading";
import { useSyncFormTemplate } from "@/lib/react-query/mutationHooks/useSyncFormTemplate";
import { queryClient } from "@/lib/react-query/general";
import { QUERY_KEYS } from "@/lib/react-query/constants";

const EditRawFormPage: FC = () => {
  const navigate = useNavigate();
  const { accountId } = useAuthContext();
  const account = useAccount({
    accountId,
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { formid } = useParams();
  const editFormMutation = useEditForm();
  const { alertProps, closeAlert, setAlert } = useAlert();
  const form = account.data?.data.forms.find(
    (accountForm) => accountForm.id === formid
  );
  const formName =
    form?.fields?.find((field: any) => field?.name === "Form Description")
      ?.text ?? form?.name;

  const [formText, setFormText] = useState<string | undefined>();
  const [isInvalidJSON, setIsInvalidJSON] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { mutateAsync: syncFormTemplate, isPending: syncFormTemplatePending } =
    useSyncFormTemplate();

  const handleSave = () => {
    setAlert({
      body: "This action cannot be undone. Please take a backup of existing form just in case.",
      cancelButtonText: "Cancel",
      confirmButtonText: "Save",
      onCancel: () => {
        closeAlert();
      },
      onConfirm: async () => {
        closeAlert();
        if (!formText) return;
        await editFormMutation.mutateAsync(
          { form: formText },
          {
            onSuccess: () => {
              toast.success("Form updated successfully.");
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.ACCOUNT, { accountId }],
              });
            },
            onError: () => {
              toast.error("Error updating form.");
            },
          }
        );
        await syncFormTemplate(
          { accountId },
          {
            onSuccess: () => {
              toast.success("Account Forms Synced between databases");
            },
            onError: () => {
              toast.error(
                "Error Syncing between databases. Please contacf web team."
              );
            },
          }
        );
      },
      open: true,
      title: "Save Changes",
    });
  };

  useEffect(() => {
    setFormText(JSON.stringify(form, null, 2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.data?.data]);

  const mutationPending = editFormMutation.isPending || syncFormTemplatePending;

  return (
    <div className="w-full max-w-[1000px] pt-14">
      <a
        className={`mb-4 flex w-fit items-center gap-x-1 text-theme-black-secondary`}
        onClick={(e) => {
          e.preventDefault();
          navigate(`/settings/forms/${formid}`);
        }}
        href={`/settings/forms/${formid}`}
      >
        <ArrowLeftIcon className="h-6 w-6" />
        <span className="text-xs font-medium">Back to {formName} Detail</span>
      </a>
      <header className="mb-5 flex w-full flex-row justify-between">
        <h1 className="my-auto text-2xl font-semibold">
          Update {formName} Raw JSON
        </h1>
        <div className="my-4 flex justify-end">
          <div className="flex space-x-2">
            <Button
              label="Reset"
              variant="secondary"
              className="ml-auto rounded-full"
              onClick={() => {
                setFormText(JSON.stringify(form, null, 2));
                setIsInvalidJSON(false);
                setIsDirty(false);
              }}
            />
            <Button
              label="Save"
              variant="primary"
              disabled={isInvalidJSON || !isDirty || mutationPending}
              className="ml-auto rounded-full"
              loading={mutationPending}
              onClick={() => {
                handleSave();
              }}
            />
          </div>
        </div>
      </header>
      {account.isLoading && <LoadingSpinner />}
      <div>
        <textarea
          className={classNames(
            "h-[70vh] w-full border-2 focus:outline-none focus:ring-0",
            isInvalidJSON && "!border-red-500 ring-red-500"
          )}
          onChange={(e) => {
            try {
              JSON.parse(e.target.value);
              setIsInvalidJSON(false);
            } catch (error) {
              setIsInvalidJSON(true);
            }
            setFormText(e.target.value);
            setIsDirty(true);
          }}
          value={formText}
        />
      </div>
      <Alert {...alertProps} />
    </div>
  );
};

export default EditRawFormPage;
