import { ArrowLeftIcon, BellAlertIcon } from "@heroicons/react/24/outline";
import { Button } from "@/UI/Button";
import { FC, useState } from "react";
import { Input } from "@/UI/Input";
import { LoadingSpinner } from "@/UI/Loading";
import { Modal } from "@/UI/Modal";
import { MouseEvent as ReactMouseEvent } from "react";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { toast } from "react-toastify";
import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useCSVConfig } from "@/lib/react-query/queryHooks/useCSVConfig";
import { useDeleteFormTemplate } from "@/lib/react-query/mutationHooks/useDeleteFormTemplate";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useFormTemplate } from "@/lib/react-query/queryHooks/useFormTemplate";
import { removeNullishValues } from "@/utils/helpers/removeNullishValues";
import { GqlFormTemplate } from "@/lib/graphql/graphql";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSyncFormTemplate } from "@/lib/react-query/mutationHooks/useSyncFormTemplate";

const AccountFormDetail: FC = () => {
  const navigate = useNavigate();
  const { webGqlFormTemplates } = useFlags();
  const queryClient = useQueryClient();
  const { accountId } = useAuthContext();
  const [deleteFormModelOpen, setDeleteFormModelOpen] = useState(false);
  const [deleteFormModalConfirmInput, setDeleteFormModalConfirmInput] =
    useState("");
  const {
    mutateAsync: deleteFormTemplateAsync,
    isPending: deleteFormTemplateLoading,
  } = useDeleteFormTemplate();
  const { mutateAsync: syncFormTemplate, isPending: syncFormTemplatePending } =
    useSyncFormTemplate();

  const account = useAccount({
    accountId,
    options: { staleTime: 5 * 1000 * 60 },
  });

  const { formid } = useParams();
  const { data: csvConfig, isLoading: csvConfigLoading } = useCSVConfig({
    formId: formid ?? "",
    options: { enabled: !!formid, retry: false, staleTime: 5 * 1000 * 60 },
  });
  const hasCSVConfig = !!csvConfig?.data;

  const { data: formTemplate, isLoading: formTemplateLoading } =
    useFormTemplate({
      formTemplateId: formid ?? "",
      options: { staleTime: 5 * 1000 * 60, enabled: !!formid },
    });

  const sqlForm = removeNullishValues(
    formTemplate?.data.formTemplate
  ) as GqlFormTemplate;

  const dynamoForm = account.data?.data.forms.find(
    (accountForm) => accountForm.id === formid
  ) as any;
  const form = webGqlFormTemplates ? sqlForm : dynamoForm;

  const formName =
    form?.fields?.find((field: any) => field?.name === "Form Description")
      ?.text ?? form?.name;

  const handleNavigation = (
    event: ReactMouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
    url: string
  ) => {
    event.preventDefault();
    navigate(url);
  };

  if (!form) {
    return <LoadingSpinner />;
  }

  const displayFormJSON = {
    ...form,
    ...(form.fields && { fields: ["..."] }),
    ...(form?.subforms && { subforms: ["..."] }), // subforms -> dynamo
    ...(form?.subForms && { subForms: ["..."] }), // subForms -> sql
    ...(form.layouts && { layouts: ["..."] }),
    ...(form.alerts && { alerts: ["..."] }),
  };

  const hasDynamicDocument = (formId?: string) => {
    return account.data?.data.dynamicPages?.formDocumentsPage.find(
      (dynamicPage) => dynamicPage.formId === formId
    );
  };

  const hasWebLayout = () => {
    return !!form.layouts?.web;
  };

  const hasExistingConfigs =
    !!hasDynamicDocument(formid) || hasWebLayout() || hasCSVConfig;

  const disableConfirmDelete =
    csvConfigLoading ||
    account.isLoading ||
    formTemplateLoading ||
    deleteFormModalConfirmInput !== formName;

  const onDeleteFormTemplate = async () => {
    if (!formid) return;
    await deleteFormTemplateAsync(
      { formId: formid },
      {
        onSuccess: () => {
          toast.success("Form deleted");
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.ACCOUNT, { accountId }],
          });
        },
        onError: () => {
          toast.error("Error deleting form template from dynamo.");
        },
      }
    );
    await syncFormTemplate(
      { accountId },
      {
        onSuccess: () => {
          toast.success("Account Forms Synced between databases");
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.FORM_TEMPLATE, { formId: formid }],
          });
        },
        onError: () => {
          toast.error(
            "Error Syncing between databases. Please contacf web team."
          );
        },
      }
    );
    setDeleteFormModelOpen(false);
    navigate("/settings/forms");
  };

  return (
    <div className="w-full max-w-[1600px] pt-14">
      <a
        className="mb-4 flex w-fit items-center gap-x-1 text-theme-black-secondary"
        onClick={(e) => {
          e.preventDefault();
          navigate("/settings/forms");
        }}
        href="/settings/forms"
      >
        <ArrowLeftIcon className="h-6 w-6" />
        <span className="text-xs font-medium">Back to Forms</span>
      </a>
      <header className="mb-5 flex w-full flex-row justify-between">
        <h1 className="text-4xl font-semibold">{formName}</h1>
        <Button
          label="Delete Form"
          variant="tertiary"
          className="rounded-full"
          onClick={() => setDeleteFormModelOpen(true)}
        />
      </header>
      <div className="flex w-full space-x-4">
        <div className="w-full space-y-3">
          <textarea
            disabled={true}
            className="h-[70vh] w-full"
            defaultValue={JSON.stringify(displayFormJSON, null, 2)}
          />
        </div>
        <div className="flex w-full flex-col space-y-4">
          <a
            href={`/settings/forms/${form.id}/edit-raw-form`}
            onClick={(event) =>
              handleNavigation(
                event,
                `/settings/forms/${form.id}/edit-raw-form`
              )
            }
            title={form.id}
          >
            <div className="relative rounded-xl border-4 border-green-400 p-4 transition-all hover:bg-theme-green-quarternary">
              <h3 className="text-left text-lg font-semibold">
                Update Raw Form
              </h3>
            </div>
          </a>
          <a
            href={`/settings/forms/${form.id}/edit-web-layout`}
            onClick={(event) =>
              handleNavigation(
                event,
                `/settings/forms/${form.id}/edit-web-layout`
              )
            }
            title={form.id}
          >
            <div className="relative rounded-xl border-4 border-green-400 p-4 transition-all hover:bg-theme-green-quarternary">
              <h3 className="text-left text-lg font-semibold">
                {hasWebLayout() ? "Update" : "Create"} Web Layout
              </h3>
            </div>
          </a>
          <a
            href={`/settings/forms/${form.id}/edit-dynamic-document`}
            onClick={(event) =>
              handleNavigation(
                event,
                `/settings/forms/${form.id}/edit-dynamic-document`
              )
            }
            title={form.id}
          >
            <div className="relative rounded-xl border-4 border-green-400 p-4 transition-all hover:bg-theme-green-quarternary">
              <h3 className="text-left text-lg font-semibold">
                {hasDynamicDocument(form.id) ? "Update" : "Create"} Dynamic
                Document
              </h3>
            </div>
          </a>
          <a
            href={`/settings/forms/${form.id}/edit-csv-config`}
            onClick={(event) =>
              handleNavigation(
                event,
                `/settings/forms/${form.id}/edit-csv-config`
              )
            }
            title={form.id}
          >
            <div className="relative rounded-xl border-4 border-green-400 p-4 transition-all hover:bg-theme-green-quarternary">
              <h3 className="text-left text-lg font-semibold">
                {hasCSVConfig ? "Update" : "Create"} CSV Config
              </h3>
            </div>
          </a>
          <button
            onClick={(event) =>
              handleNavigation(
                event,
                `/settings/forms/${form.id}/edit-mobile-layout`
              )
            }
            title={form.id}
          >
            <div className="relative rounded-xl border-4 border-green-400 p-4 transition-all hover:bg-theme-green-quarternary">
              <h3 className="text-left text-lg font-semibold">
                Update Mobile Form
              </h3>
            </div>
          </button>

          <button
            onClick={(event) =>
              handleNavigation(
                event,
                `/settings/forms/${form.id}/edit-pdf-config`
              )
            }
            title={form.id}
          >
            <div className="relative rounded-xl border-4 border-green-400 p-4 transition-all hover:bg-theme-green-quarternary">
              <h3 className="text-left text-lg font-semibold">
                Update PDF Config
              </h3>
            </div>
          </button>
        </div>
      </div>
      <Modal
        cancelButtonText="Cancel"
        confirmButtonText="Delete Form"
        onCancel={() => setDeleteFormModelOpen(false)}
        onConfirm={onDeleteFormTemplate}
        open={deleteFormModelOpen}
        submit
        title={`Delete ${formName}`}
        disableConfirm={disableConfirmDelete || hasExistingConfigs}
        confirmButtonVariant="tertiary"
        innerContainerClasses="!w-[700px]"
      >
        {deleteFormTemplateLoading || syncFormTemplatePending ? (
          <div className="h-48">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <div className="mt-6 flex content-center justify-center space-x-4 rounded-lg bg-red-500 p-4 text-white">
              <BellAlertIcon width={60} height={60} fill="red" />
              <span className="my-auto">
                This will permanently delete this form, along with the mobile
                layout. Existing form submissions for this form template will
                not be able to be retrieved.
              </span>
            </div>
            <div className="my-5">
              <p className="my-4 font-bold">
                If you wish to continue, please manually delete the PDF config
                associated with this form.
              </p>
            </div>
            {hasExistingConfigs && (
              <div className="my-5 text-red-500">
                <p>
                  Delete is disabled. Before you can delete, please delete the
                  following configs associated with this form template.
                </p>
                <ul className="mt-4 list-disc px-6">
                  {hasWebLayout() && <li>Web Layout</li>}
                  {!!hasDynamicDocument(formid) && <li>Dynamic Document</li>}
                  {!!csvConfig?.data && <li>CSV Config</li>}
                </ul>
              </div>
            )}
            {!hasExistingConfigs && (
              <div className="space-y-3 rounded-md border-2 border-red-500 p-4">
                <p>
                  Type the name
                  <strong className="mx-2">{formName}</strong>
                  to delete
                </p>
                <Input
                  onChange={(e) =>
                    setDeleteFormModalConfirmInput(e.target.value)
                  }
                />
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AccountFormDetail;
