import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const timecardsBaseUrl = import.meta.env.VITE_TIMECARDS_BASE_URL;

interface UseSyncFormTemplateMutationParams {
  accountId: string;
}

interface MutationReturn {
  data: { accountId: string; success: boolean };
}

export const useSyncFormTemplate = () => {
  const { fetchWithAuth } = useAuthContext();
  return useMutation<MutationReturn, Error, UseSyncFormTemplateMutationParams>({
    mutationFn: async ({ accountId }): Promise<MutationReturn> => {
      const apiRes = await fetchWithAuth({
        url: `${timecardsBaseUrl}/syncFormTemplate/${accountId}`,
        method: "POST",
      });

      if (!apiRes.ok) {
        throw new Error(`Error Syncing Form Template`);
      }
      return apiRes.json();
    },
  });
};
