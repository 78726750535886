import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { FormTemplateQuery } from "@/lib/graphql/graphql";
import { graphql } from "@/lib/graphql";

interface UseFormTemplateQueryParams {
  formTemplateId: string;
  options?: Partial<DefinedInitialDataOptions<{ data: FormTemplateQuery }>>;
}

const FormTemplate = graphql(`
  query FormTemplate($formTemplateId: ID!) {
    formTemplate(id: $formTemplateId) {
      accountId
      divisionId
      fields {
        type
        text
        path
        name
        id
        databinding {
          expression
        }
        fields {
          type
          text
          path
          name
          id
          databinding {
            expression
          }
        }
        attributes {
          hidden
        }
      }
      id
      formVersion
      name
      subForms {
        id
        name
        fields {
          type
          text
          path
          name
          id
          databinding {
            expression
          }
          fields {
            type
            text
            path
            name
            id
            databinding {
              expression
            }
          }
        }
      }
      layouts {
        web {
          downloadName
          fields {
            type
            columns
            attributes {
              marginX
              marginY
              marginBottom
            }
            fields {
              type
              text
              source
              path
              name
              id
              columnStart
              columnEnd
              attributes {
                hideTitle
                marginBottom
                marginX
                marginY
                nowrap
                titleTextSize
                valueTextSize
                valueTextWeight
                title
                endText
                nullDash
                startId
                endId
                outId
                addButton
                mapping
                options
                edit
                editTitle
                adderFieldIds
              }
            }
          }
        }
      }
      createdAt
      alerts {
        action
        actionButton
        cancelButton
        hasTextField
        keyboard
        message
        textField
        textFieldInputId
        textFieldPrompt
        title
      }
      flagForReview
      logicRules {
        name
        rule
      }
      parameters {
        forDate
      }
      renderEngineVersion
      submitted
      templateVersion
      updatedAt
    }
  }
`);

export const useFormTemplate = (params?: UseFormTemplateQueryParams) => {
  const { graphqlRequest } = useAuthContext();
  return useQuery({
    queryKey: [QUERY_KEYS.FORM_TEMPLATE, { formId: params?.formTemplateId }],
    queryFn: async () =>
      graphqlRequest(FormTemplate, {
        formTemplateId: params?.formTemplateId ?? "",
      }),
    enabled: !!params?.formTemplateId,
    ...(params?.options && { ...params.options }),
  });
};
