/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import { MultiQuoteItemValue, RowField } from "@/types/forms/formEngine";
import { Divider } from "@/UI/Divider";
import { classNames } from "@/utils/helpers/classNames";
import { getFormSubmissionValueById } from "@/utils/helpers/forms/formRendererHelpers";
import { MultiQuoteItemFieldHeaders } from "./MultiQuoteItemFieldHeaders";

type Props = {
  fieldItem: RowField;
  value: any;
};

export const MultiQuoteItemField: FC<Props> = ({ value = [], fieldItem }) => {
  const formatCurrency = (input: string | number) => {
    return `$${Number(input).toFixed(2)}`;
  };
  const getAmount = (quoteItem: MultiQuoteItemValue) => {
    if (!quoteItem?.quantity || !quoteItem?.rate) return 0;
    return formatCurrency(Number(quoteItem.quantity) * Number(quoteItem.rate));
  };

  const getSubtotal = () => {
    if (!Array.isArray(value)) return 0;
    const sum = value?.reduce((acc: any, quoteItem: any) => {
      return (
        acc + Number(quoteItem?.quantity ?? 0) * Number(quoteItem?.rate ?? 0)
      );
    }, 0);
    return sum as number;
  };

  /**
   * From PRD
   * Pricing Information Additional Notes
   * Subtotal
   * Subtotal after discount = Subtotal - Discounts
   * Tax amount = (Subtotal after discount) × Tax Rate
   * Surcharge amount = (Subtotal after discount) × Surcharge Rate
   * Total amount = Subtotal after discount + Tax amount + Surcharge amount
   */
  const getTotal = () => {
    const subTotal = getSubtotal() ?? 0;
    const discount =
      getFormSubmissionValueById(`${fieldItem?.attributes?.discountsId}`) ?? 0;
    const subTotalAfterDiscount = subTotal - discount;
    const taxPercentage =
      getFormSubmissionValueById(`${fieldItem?.attributes?.taxId}`) ?? 0;
    const taxTotal = (taxPercentage / 100) * subTotalAfterDiscount;
    const surchargePercentage =
      getFormSubmissionValueById(`${fieldItem?.attributes?.surchargeId}`) ?? 0;
    const surchargeTotal = (surchargePercentage / 100) * subTotalAfterDiscount;
    return subTotalAfterDiscount + surchargeTotal + taxTotal;
  };

  return (
    <>
      <MultiQuoteItemFieldHeaders />
      <Divider />
      {(!value || !Array.isArray(value) || value?.length < 1) && (
        <div className="flex min-h-[200px]">
          <p className="my-auto flex w-full  justify-center text-center text-gray-700">
            No line items added, yet
          </p>
        </div>
      )}
      {value &&
        Array.isArray(value) &&
        (value as unknown as MultiQuoteItemValue[]).map((quoteItem, index) => {
          return (
            <div
              key={`${quoteItem.pricebookItemId} ${index}`}
              className="relative"
            >
              <Divider />
              <div className="grid grid-cols-9 whitespace-nowrap py-4 font-medium text-theme-black-secondary">
                <h4>{quoteItem.name}</h4>
                <div className={classNames("col-start-5")}>
                  {quoteItem.quantity}
                </div>
                <h4>{quoteItem.unit}</h4>
                <h4>{formatCurrency(quoteItem?.rate)}</h4>
                <h4 className={classNames("col-start-9")}>
                  {getAmount(quoteItem)}
                </h4>

                {quoteItem.notes && (
                  <div
                    className={classNames(
                      "col-span-full mt-2  self-center  font-medium text-theme-black-secondary"
                    )}
                  >
                    <h4
                      className={classNames(
                        "text-sm font-medium text-theme-black-secondary"
                      )}
                    >
                      Notes
                    </h4>
                    <span className="w-40">{quoteItem.notes}</span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      <Divider />
      {Array.isArray(value) && value.length > 0 && (
        <div className="my-8 flex flex-col space-y-6">
          <div className="ml-auto flex justify-end space-x-3">
            <h6>Subtotal:</h6>
            <span className="w-40 text-right">
              {formatCurrency(getSubtotal())}
            </span>
          </div>
          <div className="relative ml-auto flex justify-center space-x-3 ">
            <h6 className="my-auto">Discounts:</h6>
            <p className="w-40 text-right">
              {formatCurrency(
                getFormSubmissionValueById(fieldItem.attributes?.discountsId) ??
                  0
              )}
            </p>
          </div>
          <div className="relative ml-auto flex space-x-3">
            <h6 className="my-auto">
              Surcharge (
              {getFormSubmissionValueById(fieldItem.attributes?.surchargeId) ??
                0}
              %):
            </h6>
            <p className="w-40 text-right">
              {formatCurrency(
                ((getFormSubmissionValueById(
                  fieldItem.attributes?.surchargeId
                ) ?? 0) /
                  100) *
                  getSubtotal()
              )}
            </p>
          </div>
          <div className="relative ml-auto flex justify-center space-x-3">
            <h6 className="my-auto">
              Tax (
              {getFormSubmissionValueById(fieldItem.attributes?.taxId) ?? 0}%):
            </h6>
            <p className="w-40 text-right">
              {formatCurrency(
                ((getFormSubmissionValueById(fieldItem.attributes?.taxId) ??
                  0) /
                  100) *
                  getSubtotal()
              )}
            </p>
          </div>
          <div className="relative ml-auto flex justify-center space-x-3">
            <h6 className="my-auto text-lg">Total:</h6>
            <span className="w-40 text-right text-lg">
              {formatCurrency(getTotal())}
            </span>
          </div>
        </div>
      )}
      <Divider />
    </>
  );
};
