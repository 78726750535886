/**
 * This function maps through any large object and recursively removes
 * all null and undefined values
 */
export const removeNullishValues: (obj: any) => any = (obj) => {
  if (Array.isArray(obj)) {
    return obj
      .map(removeNullishValues) // Recursively process each item in the array
      .filter((item) => item !== null && item !== undefined); // Filter out null or undefined
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc: any, key: string) => {
      const value = removeNullishValues(obj[key]);
      if (value !== null && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }
  return obj; // Return non-object, non-array values as-is
};
